import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';

import Donation from '../components/Donation';
import ProjectsLists from '../components/Donation/ProjectsLists';
import { getListDonationProjectForSelect } from '../utils/functions';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';

import HeroGiveSection from '../components/NewGive/HeroGiveSection';
import LearnmoreSec from '../components/NewGive/LearnmoreSec';
import StaticsData from '../components/NewGive/StaticsData';
import PatronPerks from '../components/NewGive/PatronPerks';
import SupporterSection from '../components/NewGive/SupporterSection';
import TotalBudgetSec from '../components/NewGive/TotalBudgetSec';
import GiveFAQ from '../components/NewGive/GiveFAQ';
import iconInstagramAlt from '../images/icon-instagram-alt.svg';
import ThankYouPageSection from '../components/NewGive/ThankYouPage';
import CustomDonationsIndex from '../components/Donation/CustomDonations';
import useResponsiveBodyClass from '../hooks/useResponsiveBodyClass';
import StatsContainer from '../components/NewGive/StaticsComponet';

const DonatePage = (props) => {
  const {
    data: {
      bgGive,
      cityImage,
      earthImg,
      flagImage,
      lastImage,
      podcastImg,
      supportAuthor,
      personStand,
      pateronBook,
      travelMug,
      patreonThumb,
      donationsDetails,
      languageDonationPage,
      testimonials,
      podCastData,
      totalArticleCount
    },
  } = props;
  const donationDetailsData = donationsDetails.edges[0].node.data;
  const donationProjects = donationDetailsData.projects;

  const customProjectsLists = getListDonationProjectForSelect(
    donationProjects,
    languageDonationPage.edges[0].node.data.body
  );

  const defaultSelectedProject = customProjectsLists.find((item) => item.isDefaultSelected);

  const imageData = {
    cityImage,
    supportAuthor,
  };
  const learnMoreImageData = {
    earthImg,
    podcastImg,
    personStand,
    flagImage,
    lastImage,
  };
  const bodyClass = useResponsiveBodyClass('body-dark', 'body-white');
  
  const seoData = {
    title: 'Donate Page',
    metaDesc: 'Support us through donations.',
    opengraphImage: {
      sourceUrl: bgGive?.childImageSharp?.fluid?.src || '',
    },
  };


  console.log("total read count", totalArticleCount)

  return (
    <BodyClassName className={` ${bodyClass} page-give-new`}>
      <DefaultLayoutComponent seo={seoData} title="Donate Page" openGraphImage={bgGive?.childImageSharp?.fluid?.src}>
        <div className="give-page-new">
          <section className="hero-page">
            {bgGive?.childImageSharp?.fluid && (
              <BackgroundImage
                key={bgGive?.childImageSharp?.fluid?.src || 'default-key'}
                className="bg-give-hero"
                Tag="section"
                fluid={bgGive.childImageSharp.fluid}
              >
                <HeroGiveSection
                  bgClassName={'bg-off-white'}
                />
              </BackgroundImage>
            )}
          </section>

          <section className="learn-more-section">
            <LearnmoreSec podCastData={podCastData} imageData={learnMoreImageData} />
          </section>
          <section className="statics-data-section">
            <StatsContainer />
          </section>
          <section className="patron-perks-page">
            <PatronPerks pateronBookImg={pateronBook} travelMugImg={travelMug} />
          </section>
          <section className="supporter-page-section">
            <SupporterSection testimonials={testimonials} data={imageData} />
          </section>
          <section className="budget-page">
            <TotalBudgetSec />
          </section>
          <section id="faq" className="faq-give-page">
            <GiveFAQ />
          </section>
          <section className="thank-you-section">
            <ThankYouPageSection />
          </section>
          <section className="index-social">
            <h3 className="sectionHeading pd-x-md">
              <a href="https://www.instagram.com/lineagejourney/" target="_blank">
                <span>Follow Us On</span>
                <span>
                  <img src={iconInstagramAlt} alt="Instagram" className="icon-insta" />
                  Instagram
                </span>
              </a>
            </h3>
            <div className="insta-gallery">
              <div className="elfsight-app-58f89894-78f0-48a8-bb54-4529326bc8b2"></div>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    testimonials: allPrismicTestimonials {
      nodes {
        data {
          title {
            text
          }
          position {
            text
          }
          featuredImage: featured_image {
            url
          }
          description {
            text
          }
          address {
            text
          }
        }
      }
    }
    # Read total count 
    totalArticleCount: allPrismicRead {
      totalCount
    }


    podCastData: allPrismicPodcast(sort: { fields: data___priority, order: ASC }) {
      nodes {
        data {
          thumnailImage: thumbnail_image {
            url
          }
          podCastTitle: podcast_title {
            text
          }
          season {
            type
            uid
          }
          podcastDuration: podcast_duration
          priority
        }
        uid
      }
    }
    bgGive: file(relativePath: { eq: "givepage/givePageImage.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    earthImg: file(relativePath: { eq: "givepage/earth-img.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    personStand: file(relativePath: { eq: "givepage/pearson-stand.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    podcastImg: file(relativePath: { eq: "givepage/podcast.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cityImage: file(relativePath: { eq: "givepage/city-img.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    flagImage: file(relativePath: { eq: "givepage/flag.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lastImage: file(relativePath: { eq: "givepage/lastimage.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    supportAuthor: file(relativePath: { eq: "givepage/supporter-author.png" }) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 1037, maxWidth: 1780) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pateronBook: file(relativePath: { eq: "givepage/pateron-book.png" }) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 1037, maxWidth: 1780) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    travelMug: file(relativePath: { eq: "givepage/travel-mug.png" }) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 1037, maxWidth: 1780) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    patreonThumb: file(relativePath: { eq: "patreon-thumb.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    languageDonationPage: allPrismicLanguageDonationsPage {
      edges {
        node {
          data {
            body {
              ... on PrismicLanguageDonationsPageBodyLanguageProjects {
                id
                primary {
                  languageName: language_name {
                    text
                  }
                  flagCode: flag_code {
                    text
                  }
                  continent
                }
              }
            }
          }
        }
      }
    }
    donationsDetails: allPrismicDonationsPage {
      edges {
        node {
          data {
            headingDescriptions: heading_descriptions {
              text
            }
            backgroundImage: background_image {
              alt
              url
              fluid(maxWidth: 1280) {
                ...GatsbyPrismicImageFluid
              }
            }
            projects {
              title {
                text
              }
              thumbnail {
                url
              }
              goal
              shortDescriptions: short_descriptions {
                text
              }
              featured
              detailsPage: details_page {
                id
                slug
                uid
                document {
                  __typename
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default DonatePage;
