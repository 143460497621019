import React, { useEffect, useState, useMemo, memo } from "react";

const AnimatedStat = memo(({ icon, value, label }) => {
  const [animatedValue, setAnimatedValue] = useState(0);

  const targetValue = useMemo(() => {
    return parseFloat(value.replace(/[^\d.-]/g, "")) *
      (value.includes("M") ? 1_000_000 : value.includes("K") ? 1_000 : 1);
  }, [value]);

  useEffect(() => {
    let start = 0;
    const duration = 2000; 
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      setAnimatedValue(Math.floor(progress * targetValue));

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, [targetValue]);

  const formatValue = (val) => {
    if (val >= 1_000_000) return `${(val / 1_000_000).toFixed(2)}M`;
    if (val >= 1_000) return `${(val / 1_000).toFixed(1)}K`;
    return val;
  };


  return (
    <div className="stat-item">
      <div className="stat-icon">{icon}</div>
      <div className="stat-value" data-value={animatedValue}>
        {formatValue(animatedValue)} +
      </div>
      <div className="stat-label">{label}</div>
    </div>
  );
});

export default AnimatedStat;
