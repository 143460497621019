import React from 'react';

import Grid from '../Grid';
import { isBrowser } from '../../utils/isBrowser';
import { Link } from 'gatsby';

// Since OwlCarousel is not SSR compatible, we need to check if we are in the browser
const OwlCarousel = (() => {
  if (isBrowser()) {
    return require('react-owl-carousel');
  } else {
    return () => null;
  }
})();

const TourSlide = ({ tour }) => {
  return (
    <div>
      <h3>{tour.data?.title?.text}</h3>
      <h4>{tour.data?.subtitle?.text}</h4>

      <p
        className="h-clamp-line-2"
        style={{
          textAlign: 'center',
        }}
      >
        {tour.data?.description?.text}
      </p>
      <div
        style={{
          maxWidth: '890.22px',
          margin: '0 auto 64px auto',
        }}
      >
        <Grid>
          <Grid.Col xs="1 / span 2" sm="1 / span 1" md="1 / span 6">
            <img
              src={tour.data.homepagePreviewImage1.fluid.src}
              alt={tour.data.homepagePreviewImage1.alt || 'Tour image'}
              style={{
                width: '100%',
                aspectRatio: '423/238',
                objectFit: 'cover',
              }}
            />
          </Grid.Col>

          <Grid.Col xs="1 / span 2" sm="2 / span 1" md="7 / span 6">
            <img
              src={tour.data.homepagePreviewImage2.fluid.src}
              alt={tour.data.homepagePreviewImage2.alt || 'Tour image'}
              style={{
                width: '100%',
                aspectRatio: '423/238',
                objectFit: 'cover',
              }}
            />
          </Grid.Col>
        </Grid>
      </div>

      <Grid className="home-tour-btn-wrapper">
        <Grid.Col xs="1 / span 2" sm="1 / span 1" md="1 / span 6">
          <div className="secondary-btn-wrapper">
            <Link to={`/tours/${tour.uid}`} className="btn btn-md btn-outline outline-gray btn-tour-jumbotron">
              More Information
            </Link>
          </div>
        </Grid.Col>
        <Grid.Col xs="1 / span 2" sm="2 / span 1" md="7 / span 6">
          <Link to={`/tours/GV`} className="btn btn-md btn-primary-ii btn-tour-jumbotron">
            Explore All Tours
          </Link>
        </Grid.Col>
      </Grid>
    </div>
  );
};

const Tour = ({ tours }) => {
  return (
    <div className="index-tours">
      <OwlCarousel items={1} nav={true}>
        {tours.map((tour) => (
          <TourSlide key={tour.uid} tour={tour} />
        ))}
      </OwlCarousel>
    </div>
  );
};

export default Tour;
