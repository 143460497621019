import React, { useEffect, useState, useMemo } from 'react';
import AnimatedStat from './StaticsData';
import { graphql, useStaticQuery } from 'gatsby';

const StatsContainer = () => {
    const data = useStaticQuery(graphql`
        query {
          allPrismicRead {
            totalCount
          }
        }
      `);
    
  const totalReads = data?.allPrismicRead?.totalCount || 0; 
  const [ytData, setYtData] = useState(null);
  const ytViewCount = ytData?.items[0]?.statistics?.viewCount || 0;
  const ytSubscribersCount = ytData?.items[0]?.statistics?.subscriberCount || 0;

  const stats = [
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 45 42" fill="none">
          <path
            d="M42.8806 10.7656C42.4119 8.89062 40.9275 7.40625 39.1306 6.9375C35.7712 6 22.49 6 22.49 6C22.49 6 9.13061 6 5.77124 6.9375C3.97437 7.40625 2.48999 8.89062 2.02124 10.7656C1.08374 14.0469 1.08374 21.0781 1.08374 21.0781C1.08374 21.0781 1.08374 28.0312 2.02124 31.3906C2.48999 33.2656 3.97437 34.6719 5.77124 35.1406C9.13061 36 22.49 36 22.49 36C22.49 36 35.7712 36 39.1306 35.1406C40.9275 34.6719 42.4119 33.2656 42.8806 31.3906C43.8181 28.0312 43.8181 21.0781 43.8181 21.0781C43.8181 21.0781 43.8181 14.0469 42.8806 10.7656ZM18.115 27.4062V14.75L29.2088 21.0781L18.115 27.4062Z"
            fill="white"
          />
        </svg>
      ),
      //yt value here
      value: ytViewCount.toString(),
      label: 'YouTube Channel Views',
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 51 42" fill="none">
          <path
            d="M35.875 13.8125C36.7344 13.8125 37.4375 14.5156 37.4375 15.375V15.6875H42.125C42.9844 15.6875 43.6875 16.3906 43.6875 17.25C43.6875 18.1094 42.9844 18.8125 42.125 18.8125H41.9688L41.8125 19.2031C41.1094 21.0781 40.0156 22.875 38.6875 24.2812C38.7656 24.3594 38.8438 24.3594 38.9219 24.4375L40.4062 25.2969C41.1094 25.7656 41.3438 26.7031 40.9531 27.4844C40.4844 28.1875 39.5469 28.4219 38.7656 28.0312L37.2812 27.0938C36.9688 26.9375 36.5781 26.7031 36.2656 26.4688C35.4844 27.0156 34.5469 27.5625 33.6094 27.9531L33.375 28.1094C32.5938 28.4219 31.6562 28.1094 31.2656 27.3281C30.9531 26.4688 31.2656 25.6094 32.0469 25.2188L32.3594 25.1406C32.8281 24.9062 33.375 24.5938 33.8438 24.3594L32.8281 23.4219C32.2812 22.7969 32.2812 21.7812 32.8281 21.1562C33.4531 20.5312 34.4688 20.5312 35.0938 21.1562L36.1875 22.3281H36.2656C37.2031 21.3125 37.9844 20.1406 38.6094 18.8125H30.25C29.3125 18.8125 28.6875 18.1094 28.6875 17.25C28.6875 16.3906 29.3125 15.6875 30.25 15.6875H34.3125V15.375C34.3125 14.5156 34.9375 13.8125 35.875 13.8125ZM11.1875 22.5625L12.75 19.2812L14.2344 22.5625H11.1875ZM45.25 6C47.9844 6 50.25 8.26562 50.25 11V31C50.25 33.8125 47.9844 36 45.25 36H5.25C2.4375 36 0.25 33.8125 0.25 31V11C0.25 8.26562 2.4375 6 5.25 6H45.25ZM45.25 9.75H25.25V32.25H45.25C45.875 32.25 46.5 31.7031 46.5 31V11C46.5 10.375 45.875 9.75 45.25 9.75ZM14.1562 14.75C13.9219 14.2031 13.2969 13.8125 12.75 13.8125C12.125 13.8125 11.5 14.2031 11.2656 14.75L6.26562 26C5.95312 26.7812 6.26562 27.7188 7.04688 28.1094C7.82812 28.4219 8.76562 28.1094 9.15625 27.3281L9.85938 25.6875H15.5625L16.2656 27.3281C16.6562 28.1094 17.5938 28.4219 18.375 28.1094C19.1562 27.7188 19.4688 26.7812 19.1562 26L14.1562 14.75Z"
            fill="white"
          />
        </svg>
      ),
      value: '50',
      label: 'Languages Translated Into',
    },
    {
      icon: (
        <svg width="50" height="50" viewBox="0 0 55 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M38 34V36C38 37.1046 37.1046 38 36 38H16C14.8954 38 14 37.1046 14 36V34C14 34 14 26 26 26C38 26 38 34 38 34ZM32 16C32 14.8133 31.6481 13.6533 30.9888 12.6666C30.3295 11.6799 29.3925 10.9109 28.2961 10.4567C27.1997 10.0026 25.9933 9.88378 24.8295 10.1153C23.6656 10.3468 22.5965 10.9182 21.7574 11.7574C20.9182 12.5965 20.3468 13.6656 20.1153 14.8295C19.8838 15.9933 20.0026 17.1997 20.4567 18.2961C20.9108 19.3925 21.6799 20.3295 22.6666 20.9888C23.6533 21.6481 24.8133 22 26 22C27.5913 22 29.1174 21.3679 30.2426 20.2426C31.3679 19.1174 32 17.5913 32 16ZM38.4 26.12C39.4933 27.1286 40.3747 28.3449 40.9928 29.6978C41.6109 31.0508 41.9533 32.5133 42 34V36C42 37.1046 42.8954 38 44 38H46C47.1046 38 48 37.1046 48 36V34C48 34 48 27.1 38.4 26.12ZM36 10C35.3957 10 34.7951 10.0945 34.22 10.28C35.3901 11.9579 36.0175 13.9544 36.0175 16C36.0175 18.0456 35.3901 20.0421 34.22 21.72C34.7951 21.9055 35.3957 22 36 22C37.5913 22 39.1174 21.3679 40.2426 20.2426C41.3679 19.1174 42 17.5913 42 16C42 14.4087 41.3679 12.8826 40.2426 11.7574C39.1174 10.6321 37.5913 10 36 10ZM16 22C16 20.8954 15.1046 20 14 20H10V16C10 14.8954 9.10457 14 8 14C6.89543 14 6 14.8954 6 16V20H2C0.89543 20 0 20.8954 0 22C0 23.1046 0.895431 24 2 24H6V28C6 29.1046 6.89543 30 8 30C9.10457 30 10 29.1046 10 28V24H14C15.1046 24 16 23.1046 16 22Z" fill="white"/>
        </svg>
        
      ),
      value: ytSubscribersCount.toString(),
      label: 'Youtube subscribers',
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 51 42" fill="none">
          <path
            d="M29.3486 15.7266H8.84863C6.79863 15.7266 5.125 17.3594 5.125 19.3672V34.3672C5.125 36.3672 6.79863 38 8.84863 38H29.3486C31.4066 38 33.0723 36.3672 33.0723 34.3672V19.3672C33.0803 17.3594 31.4066 15.7266 29.3486 15.7266ZM35.5077 22.5464C35.1641 22.714 34.9461 23.0628 34.9461 23.4451V31.2892C34.9461 31.6715 35.1641 32.0204 35.5077 32.188L44.6866 36.6655C45.3509 36.9896 46.125 36.5059 46.125 35.7668V18.9676C46.125 18.2285 45.3509 17.7448 44.6866 18.0689L35.5077 22.5464ZM10.7145 13.9062C13.8055 13.9062 16.3039 11.4609 16.3039 8.45312C16.3039 5.44531 13.8055 3 10.7145 3C7.63145 3 5.125 5.44531 5.125 8.45312C5.125 11.4609 7.63145 13.9062 10.7145 13.9062ZM27.4908 3C24.3998 3 21.9014 5.44531 21.9014 8.45312C21.9014 11.4609 24.4078 13.9062 27.4908 13.9062C30.5818 13.9062 33.0803 11.4609 33.0803 8.45312C33.0803 5.44531 30.5738 3 27.4908 3Z"
            fill="white"
          />
        </svg>
      ),
      value: '1000+',
      label: 'Videos Produced',
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 46 46" fill="none">
          <path
            d="M13.4167 32.5833H26.8333V28.75H13.4167V32.5833ZM13.4167 24.9167H32.5833V21.0833H13.4167V24.9167ZM13.4167 17.25H32.5833V13.4167H13.4167V17.25ZM9.58333 40.25C8.52917 40.25 7.62706 39.875 6.877 39.1249C6.12694 38.3749 5.75128 37.4721 5.75 36.4167V9.58333C5.75 8.52917 6.12567 7.62706 6.877 6.877C7.62833 6.12694 8.53044 5.75128 9.58333 5.75H36.4167C37.4708 5.75 38.3736 6.12567 39.1249 6.877C39.8763 7.62833 40.2513 8.53044 40.25 9.58333V36.4167C40.25 37.4708 39.875 38.3736 39.1249 39.1249C38.3749 39.8763 37.4721 40.2513 36.4167 40.25H9.58333ZM9.58333 36.4167H36.4167V9.58333H9.58333V36.4167Z"
            fill="#E8E8E8"
          />
        </svg>
      ),
      value: totalReads.toString(),
      label: 'Original Articles',
    },
  ];


  useEffect(() => {
    const fetchYoutubeData = async () => {
      const CHANNEL_ID = process.env.GATSBY_YOUTUBE_CHANNEL_ID;
      const YT_API_KEY = process.env.GATSBY_YOUTUBE_API_KEY;
      const url = `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${CHANNEL_ID}&key=${YT_API_KEY}`;
      try {
        const response = await fetch(url);
        const json = await response.json();
        setYtData(json);
      } catch (error) {
        console.error('Error fetching YouTube data:', error);
      }
    };
    fetchYoutubeData();
  }, []);

  const memoizedStats = useMemo(() => stats, [stats]);
  return (
    <div className="stats-container">
      {memoizedStats.map((stat, index) => (
        <AnimatedStat key={index} icon={stat.icon} value={stat.value} label={stat.label} ytData={ytData} />
      ))}
    </div>
  );
};

export default StatsContainer;
