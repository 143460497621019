import React, { useState, useContext, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { isEmpty, isNil } from 'lodash';
import { AppContext } from '../../context/AppContext';
import iconCart from '../../../images/icon-cart.svg';
import iconArrowRight from '../../../images/icon-arrow-right.svg';
import Level1Menu from './Level1Menu';
import Level2Menu from './Level2Menu';
import Level3Menu from './Level3Menu';
import CurrecySelect from '../currencyswitcher/currency-select';
import { AppContext as CurrencyContext } from '../../../apollo/wrap-root-element';
import { toast } from 'react-toastify';

export default function SidebarComponent({ opened, onClose, openCart, menuData, isTimeline, gcbooks }) {
  const [cart] = useContext(AppContext);
  const [activeLevel1, setActiveLevel1] = useState();
  const [activeLevel2, setActiveLevel2] = useState();
  const [activeLevel3, setActiveLevel3] = useState();

  const { setCustomerTokenDetails, setCurrentUserDetails, currentUserDetails } = useContext(CurrencyContext);
  const closeLevel3Modal = () => {
    setActiveLevel3();
    if (activeLevel2 && activeLevel2.length === 1) setActiveLevel2();
  };
  const [keyWord, setKeyWord] = useState('');

  const navigateToSearchPage = () => {
    navigate(`/search?keyword=${keyWord}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      navigateToSearchPage();
    }
  };

  const [firstPathName, setFirstPathName] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setFirstPathName(window.location.pathname.split('/')[1]);
    }
  }, []);

  return (
    <>
      <div className={`popup popup-nav popup-navbar ${opened ? 'active' : ''}`}>
        <div className="wrapper wrapper-xl pd-x-md nav-top background-white-nav">
          <div className="block d-flex">
            <div className="sidebar-currency">
              <CurrecySelect mode="dark" />
            </div>
            <a className="nav-cart" href="#" onClick={openCart}>
              <img src={iconCart} alt="Cart" />
              {cart && <span className="cart-num">{cart.totalProductsCount}</span>}
            </a>
            <Link to="/give" className="btn btn-md btn-primary-ii nav-give " style={{ marginRight: '32px' }}>
              Give
            </Link>

            <div className={`nav-bars ${opened ? 'open close-icon-nav' : ''}`} onClick={onClose}>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="nav-bottom">
          <div className="d-flex">
            <div className="block">
              <div className="search-box">
                <div className="search-box_bar">
                  <input
                    type="search"
                    placeholder="Search"
                    className="search-box_input"
                    value={keyWord}
                    onChange={(e) => setKeyWord(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </div>
                <div className="search-box_button">
                  <a href="#" className="btn-search" onClick={navigateToSearchPage}>
                    <img src={iconArrowRight} alt="Arrow Right" />
                  </a>
                </div>
              </div>
              <ul
                className={`menu-primary ${isTimeline ? '' : 'display-none'}`}
                id={
                  firstPathName === 'books' || firstPathName === 'timeline' || firstPathName === 'timeline-details'
                    ? 'showPrimaryMenuForBooksAndTimeline'
                    : 'hidePrimaryMenu'
                }
              >
                <li className="hasChild">
                  <Link to="/watch">Watch</Link>
                  <span className="after" onClick={() => setActiveLevel1('watch')} />
                </li>
                <li className="hasChild">
                  <Link to="/read">Read</Link>
                  <span className="after" onClick={() => setActiveLevel1('read')} />
                </li>
                <li>
                  <Link to="/podcast">Listen</Link>
                </li>
                <li>
                  <Link to="/books/the-great-controversy">Study</Link>
                </li>
                <li>
                  <Link to="/shop">Shop</Link>
                </li>
                <li>
                  <Link to="/give">Give</Link>
                </li>
                <li>
                  <Link to="/tours/GV">Tours</Link>
                </li>
              </ul>
              <ul
                className={`${!isTimeline ? 'menu-secondary' : 'menu-secondary-timeline'}`}
                style={{ marginTop: isTimeline ? '20px' : '24px' }}
              >
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/team">Meet The Team</Link>
                </li>

                <li>
                  <Link to="/episode_category/bts">Behind The Scenes</Link>
                </li>
                <li>
                  <Link to="/faq">Faq</Link>
                </li>
                <li>
                  <Link to="/subscribe">Subscribe</Link>
                </li>
                <li>
                  <Link to="/testimonial">Testimonials</Link>
                </li>
                <li>
                  <Link to="/presskit">Presskit</Link>
                </li>
                <li>
                  <Link to="/language">Languages</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                {currentUserDetails?.customer ? (
                  <li>
                    <Link to="https://lineage-journey.myshopify.com/account/login?return_url=%2Faccount">
                      My Downloadable Products
                    </Link>
                  </li>
                ) : (
                  ''
                )}
                {currentUserDetails?.customer ? (
                  <li>
                    <Link to="/books/the-great-controversy">Study History</Link>
                  </li>
                ) : (
                  ''
                )}
                {currentUserDetails?.customer ? (
                  <li>
                    <Link to="/donation-history">Giving History</Link>
                  </li>
                ) : (
                  ''
                )}

                {currentUserDetails?.customer ? (
                  <li>
                    <Link to="/recurring-donations">Giving Subscription</Link>
                  </li>
                ) : (
                  ''
                )}

                {currentUserDetails?.customer ? (
                  <li
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        localStorage.removeItem('shopifyAccessToken');
                        localStorage.removeItem('shopifyAccessTokenExpiresAt');
                        setCustomerTokenDetails(undefined);
                        setCurrentUserDetails(undefined);
                        toast.success('Log Out Successful', {
                          hideProgressBar: true,
                          position: 'bottom-center',
                        });
                      } else return;
                    }}
                  >
                    <Link to="/">Logout</Link>
                  </li>
                ) : (
                  ''
                )}

                {!currentUserDetails?.customer ? (
                  <li className="hasChild">
                    <a href={'/login'} rel="noopener noreferrer">
                      My Account
                    </a>
                  </li>
                ) : (
                  ''
                )}
              </ul>
              <Link
                to="/give"
                className="btn btn-primary-ii btn-donate"
                style={{ width: '100%', marginBottom: '58px', paddingTop: '13px', paddingBottom: '13px' }}
              >
                Give
              </Link>
            </div>
          </div>
        </div>
        <div className="popup-overlay" onClick={onClose} />
      </div>
      <Level1Menu
        active={activeLevel1}
        setActive={setActiveLevel1}
        setActiveNextLevel={(data) => (Array.isArray(data) ? setActiveLevel3(data) : setActiveLevel2(data))}
        data={activeLevel1 ? menuData[`${activeLevel1}Data`] : null}
      />
      <Level2Menu active={activeLevel2} setActive={setActiveLevel2} setActiveNextLevel={setActiveLevel3} />
      <Level3Menu
        active={activeLevel3 || (activeLevel2 && activeLevel2.length === 1 ? activeLevel2[0] : null)}
        closeLevel3Modal={closeLevel3Modal}
      />
    </>
  );
}
