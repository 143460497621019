import React from 'react';
import { Link } from 'gatsby';
import BodyClassName from 'react-body-classname';

import Grid from '../../components/Grid';
import IconSuccess from '../../images/icon-action-success.png';
import DefaultLayoutComponent from '../../modules/layouts/DefaultLayoutComponent';

const TourBooking = () => {
  const currentYear = new Date().getFullYear();

  return (
    <BodyClassName className="body-dark">
      <DefaultLayoutComponent>
        <div className="site-main">
          <div className="wrapper wrapper-xl pd-x-md">
            <div className="bg-light dialog-inner booking-success">
              <Grid>
                <Grid.Col md="2 / span 10" xs="1 / span 2">
                  <img src={IconSuccess} alt="Icon Success" role="presentation" />
                  <h1>Your Booking has been confirmed!</h1>
                  <p>
                    We appreciate your trust in our services and can't wait to provide you with an amazing tour package.
                  </p>

                  <div
                    style={{
                      maxWidth: '345px',
                      margin: '32px auto 0 auto',
                    }}
                  >
                    <Link to="/tours/GV" className="btn btn-md btn-primary-ii btn-submit w-full">
                      Back To Tours
                    </Link>
                  </div>

                  <p className="copyright-text">© Lineage Journey {currentYear}.</p>
                </Grid.Col>
              </Grid>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export default TourBooking;
